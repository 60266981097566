.fos-stacked-input-item {
  --inner-padding-end: 0;
}

.fos-stacked-input-item ion-label {
  margin-top: 0;
  line-height: 1.5;
}

.md .fos-stacked-input-item ion-label {
  margin-bottom: 8px;
}

.fos-stacked-input-item::part(native) {
  padding: 0;
}

.fos-stacked-input {
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.fos-stacked-input input {
  height: 46px;
  --padding-start: 20px;
}

.fos-stacked-input-label {
  color: var(--light-grey3);
  font-size: 15px;
}
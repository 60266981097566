.fos-label-value-item-style {
    --inner-padding-end: 0;
}

.fos-label-value-item-style::part(native) {
    padding: 0;
}

.fos-label-style {
    color: var(--light-grey3);
    margin-left: 10px;
    margin-right: 10px;
}

.fos-value-style {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.md .fos-value-style {
    margin-right: 10px;
}
.levels-container {
    margin-bottom: 10px;
}

.levels-room-button {
    --background: #FEFBF8;
    --background-activated: #FFFFFF;
    --background-focused: #FEFBF8;
    --color: #F7921E;
    font-weight: 600;
    margin: 0px;
}

.divider {
    border: 1px solid #F9F9FB;
}

.levels-done-footer {
    padding-top: 1px;
}

@media (prefers-color-scheme: dark) {
    .levels-room-button {
        --background: var(--dark-grey6);
        --color: var(--light-grey-7);
    }

    .levels-done-footer ion-button {
        --background: var(--dark-grey6);
    }
}
.ion-button-red-outline {
  width: 100%;
  padding: 0 20px;
  font-weight: 600;
  text-transform: none;

  --background: 'transparent';
  --background-activated: light;
  --color: #F1493F;
  --border-radius: 8px;
  --border-color: #F1493F;
  --border-style: solid;
  --border-width: 1px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --box-shadow: none;
}

ion-select::part(icon) {
  content: url('/public/assets/images/icons/ic_dropdown.png');
  width: 18px;
  height: 18px;
}
.inspection-menu-container {
    display: flex;
    background-color: #FFFFFF;
}

.inspection-menu-label {
    width: 56px;
    height: 56px;    
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #F7F7FB;
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #A5A9BC;
    border-color: #F7F7FB;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
}

.inspection-menu-selected {
    border-color: #F6831D;
    color: #1C244F;
}

.inspection-menu-unselected {
    border-color: #FFFFFF;
}

@media (prefers-color-scheme: dark) {
    .inspection-menu-container {
        background-color: var(--dark-blue-4);
    }
    .inspection-menu-label {
        color: #FFFFFF;
        border-bottom: 2px solid var(--ion-color-step-100);
    }
    .inspection-menu-selected {
        font-weight: bold;
        border-color: #F6831D;
    }
}
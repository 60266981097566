.phone-icon {
    stroke: var(--light-blue1);
}

.location-icon {
    color: var(--light-blue1);
}

.location-pin-wrapper {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: var(--dark-grey-1);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.location-pin-icon {
    color: var(--light-blue2);
}

.download-info {
    margin-left: auto;
}

.download-icon {
    color: var(--light-blue2);
    height: 30px;
    width: 50px;
}


@media (prefers-color-scheme: dark) {
    .job-card {
      background: var(--dark-grey-7) !important;
    }

    .job-cta-container {
        background: var(--dark-grey6) !important;
    }

    .job-card-title {
        color: var(--dark-grey-1) !important;
    }

    .job-address {
        color: var(--dark-grey-2) !important;
    }

    .phone-icon {
        stroke: var(--light-blue4);
    }

    .location-icon {
        color: var(--light-blue4);
    }

    .location-pin-wrapper {
        background: var(--dark-grey-9);
    }

    .location-pin-icon {
        color: var(--dark-blue2);
    }

    .download-icon {
        color: var(--dark-blue2);
    }
}
.upload-selected {
  margin-bottom: 20px;
  text-align: center;
}
.fos-label-value-item-style {
  --inner-padding-end: 0;
  display: block;
}

.fos-label-value-item-style::part(native) {
  padding: 0;
}
.file-name {
  color: #898989;
  margin-right: 70px;
  margin-left: 70px;
}

.move-to-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-photo-label {
  color: #898989;
}

.select-label {
  color: #898989;
  font-weight: 600;
}

.drop-down {
  flex: 1;
}

.phase-selector-section {
  display: flex;
  margin-bottom: 20px;
  gap: 21px;
}

.scope-of-work-section {
  margin-bottom: 20px;
}

.select {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 100%;
  --padding-start: 16px;
}

.no-padding-item {
  --padding-start: 0;
  margin-top: 10px;
  --inner-padding-end: 0;
}

.scope-of-work {
  padding-left: 5px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  min-height: 100px;
  margin-top: 10px;
}

.photo-upload-option {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid var(--border-color);
}

.upload-option-section {
  display: flex;
  gap: 15px;
}

.upload-option-section col {
  flex-direction: column;
}


.invalid-entry-message {
  color: var(--ion-color-danger);
  padding-left: 5px;
  padding-top: 2px;
  font-size: small;
}

.margin-bottom-adjust{
  margin-bottom: 10px;
}

.add-padding{
  padding-top: 10px;
}
.fos-datetime-button-no-value{
  display: none;
}

.wa-select-date-label{
  padding-right: 20px;
}
.button-fill-margin{
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
}

.work-auth-border-color{
  border: 1px solid var(--ion-color-step-250, #c8c7cc);
  border-radius: 8px;
  padding-left: 5px;
  margin-top: 10px;
}
.estimate-label{
  margin-bottom: 10px;
}
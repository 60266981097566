.fos-toolbar {
    --background: var(--light-blue4);
    --border-width: 0 !important;
}

@media (prefers-color-scheme: dark) {
    .fos-toolbar {
        color: var(--dark-grey-1);
        --background: var(--dark-grey6);
    }
}
.sketch-tool-inline-text-edit-textarea,
.sketch-tool-inline-text-edit-textarea:focus {
  border: none;
  outline: none;
  font-family: sans-serif;
  position: absolute;
  padding: 0;
  margin: 0;
  overflow: hidden;
  resize: none;
}

.sketch-tool-inline-text-edit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
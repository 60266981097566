.user-expenses-for-approval-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;
}

.user-expenses-for-approval-toolbar {
  display: flex;
  flex: 1;
  gap: 6px;
  margin-top: 10px;
}

.user-expense-for-approval-content {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 75px;
}

.user-expense-for-approval-content-details {
  margin-top: 175px;
}

.user-expenses-for-approval-category {
  display: flex;
  align-items: center;
}

.user-expenses-for-approval-custom-ion-toolbar {
  position: fixed;
  margin-top: 60px;
  --min-height: 65px;
  --border-width: 0 !important;
  --background: white;
  --border-width: 0 !important;
}

.user-expenses-for-approval-custom-ion-toolbar-button {
  position: fixed;
  --background: white;
}

.user-expenses-for-approval-category-label { 
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  margin-right: 30px;
}

.user-expenses-for-approval-header #search-user-expenses-for-approval-bar {
  --background: var(--ion-toolbar-background);
  border: 1px solid var(--light-grey6);
  border-radius: 8px;
  height: 46px;
  padding: 0;
  color: var(--light-grey3);
}

@media (prefers-color-scheme: dark) {
  .user-expenses-for-approval-body {
    --ion-background-color: var(--dark-grey-9);
  }
  
  .user-expenses-for-approval-custom-ion-toolbar {
    --background: var(--dark-grey-9);
    --ion-toolbar-background: var(--dark-grey-9);
  }
  .user-expenses-for-approval-custom-ion-toolbar-button {
    --background: var(--dark-grey-9);
    --ion-toolbar-background: var(--dark-grey-9);
  }
}

.user-expenses-for-approval-div-filter {
  margin-bottom: 15px;
  margin-top: 10px;
}

.user-expenses-for-approval-ion-label {
  padding-left: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
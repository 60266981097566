.ion-tab-bar {
  background-color: #1c244f;
  padding-top: 15px;
  padding-bottom: 15px;
}

.ion-tab-button {
  background-color: transparent;
  --color-selected: #F7921E;
  --color: #707590;
}

.ion-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.app-container {
  position: relative;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .ion-tab-bar {
    background-color: var(--dark-grey6);
  }
}
.autocomplete-search-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}

.autocomplete-search {    
    border: 1px solid #c8c7cc !important;
    border-radius: 8px;
    background: transparent;
    white-space: inherit;
    color: inherit;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    padding-left: var(--padding-start);
    padding-right: var(--padding-end);
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: inherit;
    text-overflow: inherit;
    text-transform: inherit;
    text-align: inherit;
    white-space: inherit;
    color: inherit;
    display: inline-block;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    outline: none;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 18px;
    letter-spacing: -0.02em;
}

.suggestion-area {
    border: 1px solid var(--dark-grey6);
    border-radius: 0px 0px 8px 8px;
    position: absolute;
    top: 68px;
    z-index: 10;
    background: var(--dark-grey-1);
    width: 100%;
}
.active {
    background: var(--light-grey6);
}

@media (prefers-color-scheme: dark) {
    .suggestion-area {
        background: var(--dark-grey-7);
    }
    .active {
        background: var(--dark-grey6);
    }
    .autocomplete-search {    
        border: 1px solid #404040 !important;
    }
}

.suggestion {
    padding: 5px 5px;
}
.tools-action-button {
  --background: var(--light-blue1);
}

.tools-action-button-icon {
  color: var(--light-orange1);
}

@media (prefers-color-scheme: dark) {
    .jobs-body {
      --ion-background-color: var(--dark-grey-9);
    }

    .tools-action-button {
      --background: var(--dark-grey6);
    }

    .tools-action-button-icon {
      color: var(--light-grey-7);
    }
  }
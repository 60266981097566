.select-label {
  color: #898989;
  font-weight: 600;
}

.certificate-of-completion-section {
  margin-bottom: 20px;
}

.customer-comments {
  padding-left: 5px;
  border-radius: 8px;
  border: 1px solid #e7e7ef;
  min-height: 100px;
  margin-top: 10px;
}
.owner-info {
  border: 1px solid var(--ion-color-step-250, #c8c7cc);
  border-radius: 8px;
  padding-left: 5px;
  margin-top: 10px;
}
.text-grey {
  color: var(--light-grey3);
}
.fos-certificate-select-label-coc  {
  color: var(--light-grey3);
  margin-left: 8px;
  margin-right: 10px;
}
.invalid-entry-message {
  color: var(--ion-color-danger);
  padding-left: 5px;
  padding-top: 2px;
  font-size: small;
}

.margin-bottom-adjust {
  margin-bottom: 10px;
}
.button-fill-margin {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
}

.coc-border-color {
  border: 1px solid var(--ion-color-step-250, #c8c7cc);
  border-radius: 8px;
  padding-left: 5px;
  margin-top: 10px;
}

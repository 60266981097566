.profile-picture::part(image) {
    border-radius: 50%;
}

@media (prefers-color-scheme: dark) {
    .profile-picture-placeholder {
        fill: var(--dark-grey6);
    }

    .username {
        color: var(--dark-grey-1);
    }

    .profile-content {
        color: var(--dark-grey-1);
    }

    .profile-heading {
        color: var(--ion-text-color, #000) !important;
    }
}
.documents-header {
    display: flex;
    flex-direction: row;
}

.documents-header #search-jobs-bar {
    --background: var(--ion-toolbar-background);
    border: 1px solid #E7E7EF;
    border-radius: 8px;
    height: 44px;
    padding: 0;
    margin-right: 8px;
    color: var(--light-grey3);
}

.documents-foot-icon {
    stroke: #F7921E;
    height: 22px;
}
.when-date-time {
  display: inline-flex;
  width: 100%;
}

.when-label {
  align-self: center;
}

.when-date-input {
  margin: 0 25px;
}

ion-modal#send-to-xa-modal {
  --width: 280px;
  --min-width: 250px;
  --height: 250px;
  
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#send-to-xa-modal {
  --width: 280px;
  --min-width: 250px;
  --height: 190px;
  
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#send-to-xa-modal h1 {
  margin: 20px 20px 10px 20px;
}

ion-modal#send-to-xa-modal ion-icon {
  margin-right: 6px;

  width: 48px;
  height: 48px;

  padding: 4px 0;

  color: #aaaaaa;
}

ion-modal#send-to-xa-modal .wrapper {
  margin-bottom: 10px;
}

.message-template-div {
  padding-bottom: 10px;
}

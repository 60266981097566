.badge-page {
  height: 100%;
  width: 100%;
}
.badge-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 25px;
  height: 667.4px;
  width: 375px;
  border-radius: 10px;
  align-items: center;
}
.branch-region-qr-container {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  align-content: space-between;
  margin-top: 8px;
}
.branch-region-container {
  padding: 1em 0;
}
.no-picture-icon {
  height: 240px;
  stroke: var(--light-blue1);
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  margin-bottom: 10px;
  width: auto;
}
.logo > path:nth-of-type(1) {
  fill: black;
}
@media (prefers-color-scheme: dark) {
  .no-picture-icon {
    stroke: var(--dark-grey-1);
  }
  .logo > path:nth-of-type(1) {
    fill: var(--dark-grey-1);
  }
}
.job-title {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 2px;
  text-align: center;
  color: var(--fos-orange);
}
.corporate {
  text-transform: uppercase;
  font-weight: 300;
  font-size: 20px;
}
.badge-picture {
  height: 240px;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  margin-bottom: 10px;
}
.icon {
  width: 180px;
  height: 180px;
}
.logo {
  width: 125px;
  margin-top: 8px;
  margin-bottom: 12px;
}
.branch-region {
  font-size: 1.5em;
  float: left;
  font-weight: 100;
}
.qr-code {
  width: 35%;
  border-radius: 10px;
  margin-left: auto;
}
.name {
  font-size: 27px;
  font-weight: 600;
}
.no-scroll {
  --overflow: hidden;
}
.modal-qr {
  position: relative;
  top: 50%;
  transform: translateY(-60%);
}

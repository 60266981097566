.bg-same-as-content {
  --background: var(--ion-background-color, #fff);
  background: var(--background);
}

.control-border {
  border: 1px solid #E7E7EF;
  border-radius: 8px;
}

.status-bar {
  position: relative;
  height: 50px;
  background-color: var(--light-orange1);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
}

.upload-bar {
  background-color: var(--light-green1);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 50px;
}

.header {
  --padding-top: 21px;
  --padding-bottom: 21px;
  --background: var(--ion-background-color);
  --background-color: var(--ion-background-color);
  --color: #898989;
}

.action-sheet-button.my-custom-button {
  color: var(--light-green1);;
}

@media (prefers-color-scheme: dark) {
  .header {
    color: var(--light-grey-7);
  }

  ion-action-sheet.custom-action-sheet {
    --button-color: var(--light-grey7);
  }
}

@media (prefers-color-scheme: dark) {
  .alert-button.sc-ion-alert-ios {
    color: var(--light-grey7);
  }

}

ion-modal {
  &.center {
    --width: calc(100% - 2em);
    align-items: center;
    --height: auto;
    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }  
}

.fixed-300-width {
  &::part(content) {
    width: 300px
  }
}

.no-format-toolbar>div.ql-toolbar {
  display: none
}

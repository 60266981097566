.upload-ion-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-wrapper {
  width: 100%;
  padding: 20px 20px;
}

.upload-heading {
  color: #CBCDD4;
  text-align: center;
  margin-bottom: 20px;
}

.upload-container {
  border: 1px solid #E7E7EF;
  border-radius: 10px;
  display: flex;
}

.no-photos {
  padding-top: 80px;
}

.v-separator {
  border-right: 1px solid #E7E7EF;
}

.upload-section {
  color: #707590;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 51px;
  padding-bottom: 47px;
}

.upload-icon {
  margin-bottom: 14px;
  height: 37px;
  stroke: #1C244F;
}

.photo-footer-icon {
  stroke: #F7921E;
  height: 22px;
}

.back-arrow {
  stroke: #1C244F;
}

@media (prefers-color-scheme: dark) {
  .back-arrow {
    stroke: var(--light-grey-7);
  }
}
.upload-selected {
  margin-bottom: 20px;
  text-align: center;
}

.file-name {
  color: #898989;
  margin-right: 70px;
  margin-left: 70px;
}

.move-to-col {
  display: flex;
  align-items: top;
  justify-content: flex-end;
  overflow: hidden;
  white-space: nowrap;
}

.upload-photo-label {
  color: #898989;
}

.select-label {
  color: #898989;
  font-weight: 600;
}

.drop-down {
  flex: 1;
}

.phase-selector-section {
  display: flex;
  margin-bottom: 20px;
  gap: 21px;
}

.description-section {
  margin-bottom: 20px;
}

.select {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 100%;
  --padding-start: 16px;
}

.no-padding-item {
  --padding-start: 0;
  margin-top: 10px;
  --inner-padding-end: 0;
}

.description {
  padding-left: 5px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
  min-height: 160px;
  margin-top: 10px;
}

.photo-upload-option {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid var(--border-color);
}

.upload-option-section {
  display: flex;
  gap: 15px;
}

.upload-option-section col {
  flex-direction: column;
}

.claim-photo-detail-img {
  object-fit: cover;
  width: 100%;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.xa-submitted-desc {
  margin: -20px 20px 0 20px;
  text-align: justify;
}
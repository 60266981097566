.phase-form-button {
  width: 90%;
  position: absolute;
  bottom: 20px;  
  color: #F7921E;
  font-weight: 600;
  font-size: 15px;
}

.phase-form-item {
  margin-top: 20px;
}

@media (prefers-color-scheme: dark) {
  .phase-form-button {
    color: var(--light-grey-7);
  }
}

.details-content {
  --background: #FFFFFF;
}

.claim-details--footer {
  display: flex;
  background: #1C244F;
  border-bottom: 1px solid #898989;
}

.claim-details--footer-buttons {
  --background: trasnparent;
  --color: #F7921E !important;
  width: 100%;
}

.right-divider {
  border-right: 1px solid #898989;
}

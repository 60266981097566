ion-toolbar .search-input {
  --background: var(--ion-toolbar-background);
  border: 1px solid #E7E7EF;
  border-radius: 8px;
  height: 46px;
  padding: 0;
  margin-right: 8px;
}

ion-toolbar .search-input:not(:first-child) {
  margin-left: 8px;
}
.fos-card-header-level {
    --ion-background-color: var(--light-grey8);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

@media (prefers-color-scheme: dark) {
    .fos-card-header-level {
      --ion-background-color: var(--dark-grey-9);
      --ion-toolbar-background: var(--dark-grey-9);
      --ion-toolbar-border-color: var(--dark-grey-9);
    }
}
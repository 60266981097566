.distance-input {
  border: 1px solid var(--border-color);
  border-color: var(--border-color);
  --ion-border-color: var(--border-color);
  background: transparent;
  padding: 10px !important;
  font-size: 16px;
  border-radius: 8px;
  height: 46px;
  width: 100%;
}

.no-border {
  --inner-border-width: 0;
}
.fos-toogle-button-inline-container {
    display: flex;
    justify-content: space-evenly;
    background: white;
    align-items: center;
}

.fos-toogle-button-vertical-border {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    height: auto;
    z-index: -1;
    border: 1px solid var(--light-blue1);
    border-radius: 8px;
    margin-top: 10px;
}

.fos-toogle-button-inline {
    margin: auto;
    text-align: center;
    width: 50%;
    font-size: 15px;
    font-weight: 600;
}


.fos-toogle-button-divider {
    border-left: 1px solid var(--light-blue1);
    height: 44px;
    position: static;
}

.fos-toogle-button-inline-seleted {
    background-color: var(--light-grey6);
}

@media (prefers-color-scheme: dark) {
    .fos-toogle-button-inline-container {
        background: var(--dark-grey6);
        border: 1px solid var(--dark-grey-7);
    }

    .fos-toogle-button-divider {
        border-left: 1px solid var(--dark-grey-7);
    }

    .fos-toogle-button-inline-seleted {
        background-color: var(--dark-grey-7);
    }
}
.fos-label-input-value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fos-label-input-value-column {
    flex: 1;
    text-align: start;
}

ion-note[slot="danger"] {
    --color: red;
    font-size: 12px;
}
.container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
  }

.logoImage{
  max-width: 600px;
  width: 75%;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.loginButton {
  margin-left: auto;
  margin-right: auto;
}

.loginFooter{
  text-align: center;
}

.powerByList {
  margin-bottom: 10px;
  width: 90%;
  margin-left: 5%;
}

.powerByText {
  font-size: 17px;
  font-family: monospace;
}

.powerByImg{
  width: 140px;
  height: 55px;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.versionLabel {
  font-size: 12px;
  font-family: monospace;
}

@media (max-height: 600px) {
  .logoImage {
    max-width: 300px;
  }
}

  
  .fos-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-width: 1px;
    background: var(--light-grey-7);
    border-start-start-radius: 10px;
    border-start-end-radius: 10px;
  }
  
  .fos-card-min-height {
    min-height: 170px;
  }
  
  .fos-card-container-no-buttons {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .fos-card-inline-buttons-content {
    display: flex;
    justify-content: space-evenly;
    background: var(--light-grey6); 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    height: 42px;
  }
  
  .fos-card-button-label {
    color: var(--light-blue1);
    font-weight: 600;
    width: 50%
  }
  
  .fos-card-inline-status-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  
  .fos-card-button-divider {
    border: 1px solid white;
    height: 42px;
    position: static;
  }
  
  .fos-card-status {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
  }
  
  .fos-card-status-green-color {
    color: var(--light-green1);
  }
  
  .fos-card-status-orange-color {
    color: var(--light-orange1);
  }
  
  .fos-card-status-default-color {
    color: var(--light-blue2);
  }
  
  .fos-card-date {
    color: var(--light-orange1);
    font-size: 10px;
  }
  
  .fos-card-title {
    color: var(--light-grey1);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;
  }
  
  .fos-card-subtitle {
    color: var(--light-grey4);
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
  }
  
  .fos-card-location {
    font-size: 13px;
    color: var(--light-grey2);
    font-weight: 400px;
    line-height: 16px;
    align-items: center;
    margin-left: 6px;
  }
  
  .fos-card-location-items {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .fos-card-row {
    display: flex;
    background: var(--light-grey-7);
    padding-top: 4px;
  }
  
  .fos-card-column {
    flex: 1;
    text-align: start;
  }
  
  .fos-card-contact-label {
    font-size: 12px;
    color: var(--light-grey3);
  }
  
  @media (prefers-color-scheme: dark) {
    .fos-card-container {
      background: var(--dark-grey-7);
      color: var(--dark-grey-2)
    }

    .fos-card-inline-buttons-content {
      background: var(--dark-grey6)
    }

    .fos-card-button-divider {
      border-color: var(--dark-grey-7);
    }

    .fos-card-title {
      color: var(--dark-grey-1);
    }

   .fos-card-button-label {
    color: var(--dark-grey-1) 
   }

   .fos-card-location {
    color: var(--dark-grey-2)
   }

   .fos-card-row {
    background: var(--dark-grey-7);
   }
  }
.scan-equipment-alert .alert-wrapper {
    background-color: white;
}
.scan-equipment-footer {
    background-color: white;
}

@media (prefers-color-scheme: dark) {
    .scan-equipment-alert .alert-wrapper {
        background-color: var(--dark-grey-9);
    }

    .scan-equipment-alert .alert-button {
        color: white;
    }
}
.fos-select-item::part(native) {
  --padding-start: 0;
}

.fos-select-item ion-select {
  height: 46px;
  max-width: 100%;
  --padding-end: 0;
}

.md .fos-select-item-label-stacked ion-label {
  margin-top: 0;
  margin-bottom: 8px;
}

.fos-select-item-label-stacked ion-select {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding-left: 20px;
  --padding-end: 5px;
}

.fos-select-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.fos-select-item-label-stacked .fos-select-border {
  display: none;
}

.fos-select-error {
  font-size: 12px;
  padding-top: 5px;
  display: none;
}

.fos-select-item-label-default .fos-select-label {
  padding-left: 20px;
  font-size: 15px;
  color: var(--light-grey3);
}

.fos-select-label-text {
  font-size: 15px;
  color: var(--light-grey3);
}

.fos-select-item ion-label {
  margin: 0px 8px 0px 0;
}

.fos-select-item-label {
  vertical-align: middle;
  font-size: 15px;
}

.fos-text-with-opacity {
  opacity: 0.5;
}

.fos-select-item-placeholder {
  text-align: right;
  font-size: 15px;
}

.arrow {
  position: relative;
  height: 9px;
  width: 10px;
}
.arrow::before, .arrow::after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 0.05rem;
  height: 100%;
  transition: all 0.5s;
}
.arrow::before {
  left: -1px;
  transform: rotate(140deg);
  background-color: #3a3a3a;
}
.arrow::after {
  left: 5px;
  transform: rotate(40deg);
  background-color: #3a3a3a;
}

.note-error {
  padding-top: 5px;
  padding-left: 20px;
  font-size: 12px;
  color: var(--ion-color-danger);
}

.label-asterisk-container {
  padding-left: 0;
  display: flex;
  align-items: center;
}

.asterisk-select {
  font-size: 15px;
  margin-left: -4px;
  color: var(--light-grey3);
}

.label-choose-asterisk {
  --inner-padding-end: 0;
  display: block;
}
.fos-share-with-contact-title {
    color: var(--light-grey4);
    font-size: 14px;
}

.fos-share-with-contact-value {
    color: var(--light-blue5);
    font-size: 15px;
    font-weight: 400;
}

.sent-emails {
    margin-top: 30px;
}
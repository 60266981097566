@media (prefers-color-scheme: dark) {
  .user-settings-title {
    color: var(--dark-grey-1) !important;
  }

  .language-select {
    --placeholder-opacity: 1 !important;
  }
}

.face-id-toggle::part(native) {
  padding: 0;
}
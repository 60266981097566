.form-item-checkbox {
    background: #FFFFFF;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: #E7E7EF;
    --height: 25px;
    --width: 25px;
    --checkmark-color: #53C497;
    --background-checked: #FFFFFF;
    --border-color: #FFFFFF;
    --border-color-checked: #FFFFFF;
}

.form-item-checkbox-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 1px solid #E7E7EF;
    border-radius: 8px;
}

.add-email-icon-container {
    margin-right: 15px;
}

.add-email-label {
    display: flex;
    align-items: center;
}

@media (prefers-color-scheme: dark) {
    .form-item-checkbox {
        background: var(--dark-grey-9);
        --background-checked: var(--dark-grey-9);
        --border-color: var(--dark-grey-9);
        border-color: var(--ion-color-step-250);
        --border-color-checked: var(--dark-grey-9);
    }
    .form-item-checkbox-border {
        border: 1px solid var(--ion-color-step-250);
    }
}
.add-email-input-icon {
    margin-right: 5px;
}

.input-with-icon {
    --padding-start: 20px;
}

.recents-section {
    margin-top: 18px;
}

.recents-label {
    font-weight: 600;
    font-size: 14px;
    color: var(--light-grey4);
}
.menu-action-icon {
  stroke: #898989;
}

.active-action {
  stroke: #1C244F;
}

@media (prefers-color-scheme: dark) {
  
  .menu-action-icon { 
    stroke: #ffffff; 
  }
  
  .active-action {
    stroke: #F7921E;
  }
}
.input-text-area {
  width: 100%;
  margin-bottom: 10px;
}

.large-input-border {
  width: auto;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: #e7e7ef;
  padding-left: 10px;
}

.quill {
  border-color: var(--light-grey6);
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
}

.ql-toolbar.ql-snow {
  border: none;
  padding: 0px;
}

.ql-container.ql-snow {
  border: none;
  padding: 0px;
}

.ql-container {
  min-height: 100px;
  max-height: 200px;
  overflow: scroll;
}

@media (prefers-color-scheme: dark) {
  .site-inspection-info-body {
    --ion-background-color: var(--dark-grey-9);
  }

  .quill {
    border-color: var(--ion-color-step-250);
  }
}

.isr-label-padding {
  padding-top: 10px;
  padding-bottom: 10px;
}

.grey-test-isr {
  color: var(--light-grey3);
}



.isr-radio::part(container) {
  border: 1px solid var(--light-grey3);
}

.isr-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

.isr-radio.radio-checked::part(container) {
  background: var(--light-grey2);
  border: 1px solid var(--light-grey3);
}

.isr-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 1px 1px 0px;
  border-style: solid;

  /* transform: rotate(45deg); */
}
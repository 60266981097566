.upload-selected {
  margin-bottom: 20px;
  text-align: center;
}

.file-name {
  color: #898989;
  margin-right: 70px;
  margin-left: 70px;
}

.move-to-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-photo-label {
  color: #898989;
}

.select-label {
  color: #898989;
  font-weight: 600;
}

.drop-down {
  flex: 1;
}

.phase-selector-section {
  display: flex;
  margin-bottom: 20px;
  gap: 21px;
}

.description-section {
  margin-bottom: 20px;
}

.select {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 100%;
  --padding-start: 16px;
}

.no-padding-item {
  --padding-start: 0;
  margin-top: 10px;
  --inner-padding-end: 0;
}

.description {
  padding-left: 5px;
  border-radius: 8px;
  border: 1px solid #E7E7EF;
  min-height: 160px;
  margin-top: 10px;
}

.photo-upload-option {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 20px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid var(--border-color);
}

.upload-option-section {
  display: flex;
  gap: 15px;
}

.upload-option-section col {
  flex-direction: column;
}

.equipment-header {
  display: flex;
  flex-direction: row;
  padding-bottom: 0px;
}

.equipment-toolbar {
  display: flex;
  flex: 1;
  gap: 6px; 
}

.equipment-custom-ion-toolbar {
  --min-height: 80px;
  --border-width: 0 !important;
  --background: white;
  --border-width: 0 !important;
}

.equipment-header #search-equipment-bar {
  --background: var(--ion-toolbar-background);
  border: 1px solid var(--light-grey6);
  border-radius: 8px;
  height: 46px;
  padding: 0;
  color: var(--light-grey3);
}

.equipment-list-item-padding-bottom {
  padding-bottom: 0px;
}

@media (prefers-color-scheme: dark) {
  .equipment-body {
    --ion-background-color: var(--dark-grey-9);
  }
  
  .equipment-custom-ion-toolbar {
    --background: var(--dark-grey-9);
    --ion-toolbar-background: var(--dark-grey-9);
  }
}
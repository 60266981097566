.media-inspection-button {
  --background: #F7921E;
  --background-activated: #f3b46c;
  --background-focused: #F7921E;
  --color: #FFFFFF;
  margin-top: 20px;
  font-weight: 600;
}

.media-inspection-room-button {
  --background: #FEFBF8;
  --background-activated: #FFFFFF;
  --background-focused: #FEFBF8;
  --color: #F7921E;
  font-weight: 600;
  margin: 0px;
}

.media-inspection-accordion-item {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.media-inspection-room {
  background: #FFFFFF;
  background-color: #FFFFFF;
  color: #FFFFFF;
}

.media-inspection-more-button {
  border: 1px solid var(--light-grey-7);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: relative;
  background-color: #FFFFFF;
}

.media-inspection-divider {
  border: 1px solid #F9F9FB;
}

.media-inspection-label {
  font-weight: 600;
  color: #222222;
}

.media-inspection-options-button {
  font-weight: 600;
  font-size: 15px;
  color: #1C244F;
}

ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
  font-size: 14px;
  color: black;
  background-color: white;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--light-grey-7);
  padding: 8px;
}

.media-modal-label {
  --inner-padding-end: 0px;

  color: #898989;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 500;
}

.media-modal-input-border {
  border: 1px solid #E7E7EF;
  border-radius: 8px;
  margin-bottom: 10px;
}

.media-modal-buttons {
  padding-left: 20px;
  padding-right: 20px;
}

.media-modal-buttons-margin {
  margin-inline-end: 20px;
}

@media (prefers-color-scheme: dark) {
  .media-inspection-accordion-container {
    --ion-background-color: var(--dark-grey-9);
    --ion-toolbar-background: var(--dark-grey-9);
    --ion-toolbar-border-color: var(--dark-grey-9);
    border: 1px solid var(--ion-color-step-250);
  }
  .media-inspection-options-button {
    color: #FFFFFF;
  }
  .media-inspection-more-button {
    border-color: var(--dark-grey-4);
    background: var(--ion-background-color, #fff);
  }

  ion-accordion-group ion-accordion .ion-accordion-toggle-icon {
    border-color: var(--dark-grey-4);
    color: var(--dark-grey-4);
    background: var(--ion-background-color, #fff);
  }

  .media-inspection-button {
    --background: var(--dark-grey-4);
    --background-activated: var(--dark-grey-7);
    --background-focused: var(--dark-grey-7);
  }

  .media-inspection-room-button {
    --background: var(--dark-grey-4);
    --background-activated: var(--dark-grey-7);
    --background-focused: var(--dark-grey-7);
  }
}
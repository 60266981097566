
.fos-select-item-label {
  vertical-align: middle;
  font-size: 15px;
}

.fos-certificate-select-label  {
  color: var(--light-grey3);
  margin-left: 10px;
  margin-right: 10px;
}


.fos-label-value-item-style {
  --inner-padding-end: 0;
  display: block;
}

.fos-label-value-item-style::part(native) {
  padding: 0;
}

.max-width-fix{
  max-width: fit-content;
}

.label-container {
  display: flex;
  align-items: center;
}

.asterisk {
  margin-left: -8px;
}

.form-border-color{
  border: 1px solid var(--border-color);
  border-radius: 8px;
}
.photo-ion-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.footer-button-div {
  position: fixed;
  z-index: 100;
  bottom: 62px;
  left: 7px;
  right: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.footer-button-div-first {
  position: fixed;
  z-index: 100;
  bottom: 101px;
  left: 7px;
  right: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  pointer-events: none;
}

.footer-button {
  --background: var(--light-grey6);
  --color: var(--light-blue1);
  --border-radius: 64px;
  width: 105px;
  height: 32px;
  pointer-events: auto;
}

.right-button {
  margin-left: auto;
  margin-right: 15px;
}

.move-to-button {
  margin-left: auto;
  margin-right: -10px;
}

.photo-ion-col {
  margin: 0;
  padding: 0;
  border: 1px solid white;
}

.menu-text-size{
  font-size: 15px !important;
}

.room-container {
  position: relative;
}

.room-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 10px;
  left: 10px;
  width: 90%;
  color: white;
  -webkit-text-stroke: 0.2px black;
}

.checkbox-selected {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 12px;
  right: 12px;
  color: white;
  height: 26px;
  width: 26px;
  --background-checked: var(--light-green1);
}

ion-checkbox::part(container) {
  border-radius: 16px !important;
}

.level-label {
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.room-label {
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.add-level-room-button {
  --background: #F7921E;
  --background-activated: #f3b46c;
  --background-focused: #F7921E;
  --color: #FFFFFF;
  width: 94%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  font-weight: 600;
}

.claim-photo {
  object-fit: cover;
  height: 130px;
}

.claim-menu-container {
  display: flex;
  background-color: #FFFFFF;
}

.level-room-label {
  margin-right: 30px;
}

.arrow-back-icon {
  height: 54px;
  font-size: 23px;
  padding-left: 10px;
}

.rooms-header {
  --padding-top: 21px;
  --padding-bottom: 21px;
  --background: var(--ion-background);
}

.claim-photo-content {
  --padding-top: 50px;
}

@media (prefers-color-scheme: dark) {
  .claim-menu-container {
    background-color: var(--dark-blue-4);
  }

  .add-level-room-button {
    --background: var(--dark-grey-4);
  }

  .footer-button {
    --background: var(--dark-grey-4);
    --color: var(--dark-blue-1);
  }
}
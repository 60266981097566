.notes-header {
    display: flex;
    flex-direction: row;
    gap: 6px;
}
.claim-notes-content {
    --padding-top: 70px;
}

.notes-header #search-jobs-bar {
    --background: var(--ion-toolbar-background);
    border: 1px solid #E7E7EF;
    border-radius: 8px;
    height: 44px;
    padding: 0;
    color: var(--light-grey3);
}
.tools-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.tools-header button {
    align-self: center;
}

.tools-header-text {
    font-weight: 'bold';
}

.cellular-checkbox {
    opacity: 1;
}

.synced-status {
    color: var(--light-green1);
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
}

.unsynced-status {
    color: var(--ion-color-danger);
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
}

.status-container {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
}

.tools-icon {
    padding-right: 10px;
    color: var(--light-blue1);
}

.tools-close-button {
    height: 25px;
    width: 25px;
    color: var(--light-blue1);
}

.close-icon-container {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--light-blue4);
}

.help-button {
    margin-bottom: 10px;
}

@media (prefers-color-scheme: dark) {
    .tools-close-button {
        color: var(--light-grey-7);
    }

    .tools-icon {
        color: var(--ion-text-color);
    }

    .close-icon-container {
        background: var(--dark-grey-7);
    }
}

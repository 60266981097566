@media (prefers-color-scheme: dark) {
    .button-icon {
        background: transparent !important;
    }
    
    .button-icon ion-icon {
        color: var(--light-grey-7) !important;
    }

}

.hide-button {
    display: none !important;
}
.fos-dateinput-label-list{
    width: 100%;

}

.fos-date-label-style {
color: var(--light-grey3);
margin-left: 10px;
margin-right: 10px;
position: absolute;
z-index: 2;}

.fos-checkbox-style {
margin-right: 10px;
background: none;
border-width: 1px;
border-style: solid;
border-radius: 4px;
border-color: var(--border-color);
--height: 25px;
--width: 25px;
--checkmark-color: #53C497;
--background-checked: none;
--border-color: none;
}
.form-border-color{
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
display: none;
-webkit-appearance: none;
}

.fos-label-value-item-style {
    --inner-padding-end: 0;
    display: block;
  }
  
  .fos-label-value-item-style::part(native) {
    padding: 0;
  }
  
  
.inspection-header-title {
    font-weight: 700;
    font-size: 10px;
    text-transform: uppercase;
    color: #1C244F;
}

.inspection-header-claim {
    font-weight: 600;
    font-size: 17px;
    color: #222222;
    padding-bottom: 10px;
}

@media (prefers-color-scheme: dark) {
    .inspection-body {
        --ion-background-color: var(--ion-color-step-100);
        --ion-toolbar-background: var(--ion-color-step-150);
        --ion-toolbar-border-color: var(--ion-color-step-250);
    }
    .inspection-header-title {
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    .inspection-header-claim {
        font-weight: 600;
        font-size: 17px;
        color: #FFFFFF;
        padding-bottom: 10px;
    }
 
    .inspection-ion-toolbar {
        --background: var(--dark-blue-4);
    }
}
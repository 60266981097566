.ion-button {
  --background: #1C244F;
  --border-radius: 8px;
  --background-hover: #1C244F;
  /* --background-activated: #88f4be; */
  --background-focused: #1C244F;
  --color: #F7921E,
    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  --ripple-color: deeppink;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.phase-selector-header, .phase-form-header {
  --padding-top: 21px;
  --padding-bottom: 21px;
  --background: var(--ion-background-color);
  --background-color: var(--ion-background-color);
  --color: #898989;
}

.custom-ion-item {
  --background: var(--ion-background-color);
  --background-color: var(--ion-background-color);
  --color: #898989;
  --inner-padding-top: 21px;
  --inner-padding-bottom: 21px;
}

.custom-modal {
  --border-radius: 10px 10px 0px 0px;
}

.select-button {
  --background: #FFFFFF;
  --border-radius: 8px;
  width: 100%;
  --color: #898989;
  text-align: left;
}

.select-mask {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
}

.item-background-color {
  --background: var(--ion-background-color);
}

@media (prefers-color-scheme: dark) {
  
  .phase-selector-footer ion-button {
    --background: var(--dark-grey6);
    color: var(--light-grey-7) !important;
  }

  .phase-selector-header {
    color: var(--light-grey-7);
  }
}
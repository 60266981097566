ion-radio {
    width: 20px;
    height: 20px;
}

ion-radio::part(container) {
    border-radius: 16px;
    border: 1px solid #ddd;
}

ion-radio::part(mark) {
    background: none;
    transition: none;
    transform: none;
    border-radius: 0;
}

ion-radio.radio-checked::part(container) {
    background: #53C497;
    border-color: transparent;
}

ion-radio.radio-checked::part(mark) {
    width: 6px;
    height: 10px;

    border-width: 0px 2px 2px 0px;
    border-style: solid;
    border-color: #fff;

    transform: rotate(45deg);
}

.radio-label {
    width: 100%;
}
.job-form-button {
  font-weight: 600;
}

.phase-close-icon {
  width: 32px;
  height: 32px;
}

.custom-modal {
  --border-radius: 10px 10px 0px 0px;
}

.add-job-border-layout {
  border: 1px solid var(--border-color);
  padding: 0px 4px 0px 4px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.add-job-emergency-phase-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.add-job-delete-button-layout {
  --border-color: #F1493F;
  color: #F1493F;
  margin-bottom: 20px;
}

.list-form {
  margin-top: 10px;
}

.phase-form-header {
  --padding-top: 21px;
  --padding-bottom: 21px;
  --background: var(--ion-background-color);
  --background-color: var(--ion-background-color);
  --color: #898989;
}

@media (prefers-color-scheme: dark) {
  .ios body .add-job {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  .phase-form-header {
    color: var(--light-grey-7);
  }
}
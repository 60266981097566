.title {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  padding: 0;
  margin-left: 20px;
  width: fit-content;
}

.custom-ion-toolbar {
  --min-height: 84px;
  --border-width: 0 !important;
  --background: #FFFFFF;
  --border-width: 0 !important;
}

.custom-ion-header {
  border: none;
}

.logo-image {
  height: 30px;
}

.custom-ion-select {
  background-color: #FFFFFF;
  --placeholder-color: #898989;
  color: #898989;
  width: 100%;
  border-radius: 8px;
  padding: 2px;
}

.custom-ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

@media (prefers-color-scheme: dark) {
  .custom-ion-toolbar {
    --background: var(--dark-grey-9);
    --ion-toolbar-background: var(--dark-grey-9);
  }
}
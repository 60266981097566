.claim-details-content {
    --offset-top: 0;
}

.claim-details-container {
    color: var(--grey-2);
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
    font-size: 14px;
}

.section {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--light-grey4);
    padding: 4px 0;
}

.section-row {
    display: flex;
    flex-direction: row;
}

.section-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
    width: 175px;
}

.stand-alone-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 10px;
}

.heading {
    color: var(--light-grey4);
    font-size: 12px;
    font-weight: 700;
}

.encircle-dialog-buttons {
    font-weight: normal !important;
}

@media (prefers-color-scheme: dark) {
    .claim-details-container {
        color: var(--dark-grey-4);
    }

    .heading {
        color: var(--dark-grey-2);
    }

    .claim-details-content {
        --background: var(--dark-grey-9);
    }

    .claim-details--footer {
        background: var(--ion-color-step-150);
    }
    
}
.fos-doc-item {
  padding: 2px;
}

.fos-doc-item-thumb {
  width: auto;
  height: auto;
}

.fos-doc-item-chevron {
  width: 18px;
  height: 18px;
}

.fos-doc-label-row {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.fos-doc-item-label {
  font-weight: 400;
  font-size: 15px;
  display: flex;
  flex-direction: row;
  color: var(--light-grey1);
}

.fos-doc-item-second-label {
  margin-left: auto;
}

.fos-doc-item-subheading-label {
  font-weight: 400;
  font-size: 13px;
  color: var(--light-grey3);
}

.fos-document-more-button {
  border: 1px solid var(--light-grey-7);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: relative;
  background-color: #FFFFFF;
}

.right-chevron {
  stroke: var(--light-blue1);
}

.icon-arrow {
  margin-right: 5px;
}

@media (prefers-color-scheme: dark) {
  .fos-document-more-button {
    border-color: var(--dark-grey-4);
    background: var(--ion-background-color, #fff);
  }

  .fos-doc-item-label {
    color: var(--dark-grey-2) !important;
  }

  .fos-doc-item-second-label {
    color: var(--dark-grey-4);
  }

  .right-chevron {
    stroke: var(--dark-grey-4);
  }
}
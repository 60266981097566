.fos-currency-input {
  border: 1px solid var(--border-color);
  border-color: var(--border-color);
  --ion-border-color:  var(--border-color);
  background: transparent;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  height: 46px;
  width: 100%;
}

.fos-currency-input.disabled {
  color: var(--light-grey4);
}

.fos-currency-input:focus-visible {
  outline: none;
  box-shadow: none;
}

.fos-currency-error {
  color: var(--highlight-color-invalid);
  font-size: 12px;
  margin-left: 20px;
}
.fos-button-icon-container {
  width: 54px;
  height: 46px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: none;
}

.fos-button-icon {
  width: 25px;
  height: 25px;
}

.fos-button-icon-color-blue {
  color: var(--light-blue1);
}

.fos-button-icon-color-white {
  color: white;
}

.fos-button-icon-color-grey {
  color: var(--light-grey8);
}

.fos-button-background-white {
  background: white;
  border: 1px solid var(--light-grey6);
}

.fos-button-background-blue {
  background: var(--light-blue1);
}

.fos-button-background-orange {
  background: var(--light-orange2);
}

@media (prefers-color-scheme: dark) {
  .fos-button-icon-color-grey {
    color: var(--light-blue1);
  }

  .fos-button-background-blue {
    background: var(--dark-grey-1);
  }

  .fos-button-background-white {
    background: transparent;
  }

  .fos-button-icon-color-blue {
    color: var(--light-grey8);
  }
}
.room-inspection-divider {
  border: 1px solid #F9F9FB;
  margin-top: 10px;
}

.room-accordion-more-button {
  border: 1px solid var(--light-grey-7);
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.more-icon {
  fill: var(--light-blue1);
}

@media (prefers-color-scheme: dark) {
  .more-icon {
    fill: var(--dark-grey-4);
  }

  .room-accordion-more-button {
    border-color: var(--dark-grey-4);
  }
}
.fos-modal-header {
    --padding-top: 21px;
    --padding-bottom: 21px;
    --background: var(--ion-background-color);
    --background-color: var(--ion-background-color);
    --color: #898989;
}

@media (prefers-color-scheme: dark) {
    .fos-modal-header {
        color: var(--light-grey-7);
    }
}
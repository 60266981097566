.preview-footer {
    background: var(--ion-background-color, #fff);
}

@media (prefers-color-scheme: dark) {
    .preview-footer {
        background: var(--dark-grey-9);
    }
}

.h1-center {
    text-align: center;
}

.phase-footer {
    text-align: center;
    background: inherit;
}

.generate-btn {
    width: 80%;
}

.ion-item-border::part(native) {
    border-color: #fff;
    border-style: groove;
    border-width: 1px;

    border-radius: 10px;
    margin-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

ion-modal#phase-modal {
    --width: 280px;
    --min-width: 250px;
    --height: 250px;
    
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
  
ion-content#phase-content {
    --overflow: hidden;
    margin-top: 30px;
}

ion-modal#phase-modal h1 {
    margin: 20px 20px 10px 20px;
}

ion-modal#phase-modal ion-icon {
    margin-right: 6px;

    width: 48px;
    height: 48px;

    padding: 4px 0;

    color: #aaaaaa;
}

ion-modal#phase-modal .wrapper {
    margin-bottom: 10px;
}
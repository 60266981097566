ion-textarea.custom-textarea {
  margin-top: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-font-weight: 400;
}


.fos-text-area-border {  
  border: 1px solid #c8c7cc;
  border-radius: 8px;
}
@media (prefers-color-scheme: dark) {
  .fos-text-area-border {  
    border: 1px solid #404040;
    border-radius: 8px;
  }
}

/* .fos-select-date-item {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  height: 46px;
} */

.fos-select-date-item::part(native) {
  --padding-start: 0;
  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: transparent;
  --background-focused: transparent;
  --background-focused-opacity: transparent;
  --background-hover: transparent;
  --background-hover-opacity: transparent;
}

.fos-select-date-item ion-label {
  padding-left: 20px;
}

.fos-select-date-item ion-datetime-button {
  font-size: 12px;
}

.fos-select-date-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: 8px;
}

.fos-select-date-label {
  color: var(--light-grey3);
  font-size: 15px;
}


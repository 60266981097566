.stacked-large-input {
  width: 100%;
  min-height: 180px;
}
.stacked-large-input-select {
  width: 78%;
  margin-top: 4px;
  margin-bottom: 0px;
}

.stacked-large-input-border {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  border-color: #E7E7EF;
  padding: 10px;
}

.stacked-large-input-button-float-right {
  float:right;
}

.stacked-large-input .quill {
  border: none;
}

@media (prefers-color-scheme: dark) {
  .stacked-large-input-border {
      background: var(--dark-grey-9);
      border-color: var(--ion-color-step-250);
  }
}
.status {
  color: var(--light-green1);
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-transform: uppercase;
}

.claim-title {
  font-size: 17px;
  font-weight: 600;
}

.icon-row {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  background-color: var(--light-blue4);
}

.claim-details-menu-icon {
  width: 56px;
  height: 56px;
  flex-grow: 1;
  border-bottom: 2px solid var(--light-blue4);
  background-color: var(--light-blue4);
  margin-bottom: 0px;
}

.claim-details-menu-selected {
  border-color: var(--light-orange1);
}

.icon-selected {
  stroke: var(--light-blue1);
}

.icon-not-selected {
  stroke: var(--light-blue3);
}

.claim-details-header {
  --background: var(--light-blue4);
}

.left-chevron {
  stroke: var(--light-blue1);
  transform: rotate(180deg);
  margin-right: 5px;
}

@media (prefers-color-scheme: dark) {
  .title {
    color: var(--dark-gray-2);
  }

  .claim-details-menu-icon {
    color: var(--dark-gray-2);
  }

  .claim-details-menu-selected {
    color: var(--light-orange1);
    border-color: var(--light-orange1);
  }

  .claim-details-header {
    --background: var(--dark-blue-4);
  }

  .phase-select-mask ion-button{
    --background: var(--dark-grey-9);
  }

  .icon-row {
    background: var(--dark-blue-4);    
  }

  .claim-details-menu-icon {
    background: var(--dark-blue-4);
  }

  .claim-details-menu-not-selected {
    border-color: var(--dark-blue-4);
  }

  .icon-selected {
    stroke: white;
  }
  
  .left-chevron {
    stroke: var(--dark-grey-1);
    transform: rotate(180deg);
    margin-right: 5px;
  }
}